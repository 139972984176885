<template>
  <div class="Oil-consumption" ref="OilConsumption">
    <div class="form-area" ref="form">
      <search @getFormVal="onSubmit">
        <el-button
          v-if="form.type==2 && $store.state.menu.nowMenuList.indexOf('导出') >= 0"
          type="primary"
          size="small"
          :disabled="tableData.length === 0"
          :loading="downLoadStatus"
          @click="exportData"
        >导出</el-button>
      </search>
    </div>
    <el-tabs type="border-card" v-model="form.type">
      <el-tab-pane label="图表" name="1">
        <div class="e-c" :style="{height: `${echartsHeight}px`}">
          <charts :id="chartsId" :option="oilOptions"></charts>
        </div>
      </el-tab-pane>
      <el-tab-pane label="数据详情" name="2">
        <el-table
          :data="tableData"
          stripe
          :height="tableHeight"
          :highlight-current-row="false"
          :resizable="false"
          auto-resize
          v-el-table-infinite-scroll="load"
        >
          <el-table-column type="index" width="70" label="序号"></el-table-column>
          <el-table-column prop="gpsTime" label="时间"></el-table-column>
          <el-table-column prop="cph" label="车牌号"></el-table-column>
          <el-table-column prop="licensePlateColor" label="车牌颜色"></el-table-column>
          <el-table-column prop="teamName" label="所属车队"></el-table-column>
          <el-table-column prop="runMile" label="里程"></el-table-column>
          <el-table-column prop="velocity" label="速度"></el-table-column>
          <el-table-column prop="oilNum" label="油量(L)/电量"></el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import elTableInfiniteScroll from "el-table-infinite-scroll";
import analysisForm from "@/components/analysisForm/analysisFormNew.vue";
import charts from "@/components/charts/charts.vue";
import { queryOilConsumeCount } from '@/api/lib/api.js'
import search from './components/search'
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import { formatDate, getCurent } from "@/common/utils/index";
export default {
  components: {
    analysisForm,
    charts,
    search
  },
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll,
  },
  data () {
    return {
      chartsId: 'OilConsumption',
      tableHeight: 0,
      echartsHeight: 0,
      tableData: [],
      tableDataBase: [],//数据仓库
      form: {
        type: "1",
        vehicleNo: '',
        start: '',
        end: '',
      },
      downLoadStatus: false,
      oilOptions: null,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);

      this.oilOptions = {
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        title: {
          left: 0,
          text: '油量线性速度曲线'
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: [{
          type: 'category',
          data: [],
        }],
        yAxis: [
          {
            type: 'value',
            boundaryGap: [0, '100%']
          }
        ],
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 10
        },
        {
          start: 0,
          end: 10
        }],
        legend: {
          data: ['油量', '速度', '里程'],
          selected: {
            '油量': true,
            '速度': false,
            '里程': true
          }
        },
        series: [
          {
            name: '油量',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            data: [],
            itemStyle: {
              normal: {
                color: '#2F4554'
              }
            }
          },
          {
            name: '里程',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            data: [],
            itemStyle: {
              normal: {
                color: '#CE5C59'
              }
            }
          },
          {
            name: '速度',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            data: [],
            itemStyle: {
              normal: {
                color: '#7DB1B7'
              }
            }
          },
        ]
      }
    })
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.OilConsumption.clientHeight;
      const formHeight = this.$refs.form.clientHeight || 64;
      this.tableHeight = wholeHeight - formHeight - 40 - 30;
      this.echartsHeight = wholeHeight - formHeight - 40 - 30;
    },
    onSubmit (value) {
      this.form = { ...this.form, ...value }
      this.form.currentPage = 1;
      this.getDataList();
    },
    getDataList () {
      let data = { ...this.form, system: sessionStorage.getItem('system') }
      let oil = [], mile = [], ve = [], date = [];
      queryOilConsumeCount(data).then(res => {
        if (res.code === 1000) {
          if (res.data.oilConsumptionList) {
            this.tableDataBase = [...res.data.oilConsumptionList]
          } else {
            this.tableDataBase = [];
          }
          this.tableData = [...this.tableDataBase.splice(0, 30)];//截取数据条数
          res.data.oilConsumptionList?.forEach(item => {
            date.push(item.gpsTime);
            oil.push(item.oilNum);//油量
            ve.push(item.velocity);//速度
            mile.push(item.runMile);//里程
          })
          this.oilOptions = {
            tooltip: {
              trigger: 'axis',
              position: function (pt) {
                return [pt[0], '10%'];
              }
            },
            title: {
              left: 0,
              text: '油量线性速度曲线'
            },
            toolbox: {
              feature: {
                dataZoom: {
                  yAxisIndex: 'none'
                },
                restore: {},
                saveAsImage: {}
              }
            },
            xAxis: [{
              type: 'category',
              data: date,
            }],
            yAxis: [
              {
                type: 'value',
                boundaryGap: [0, '100%']
              }
            ],
            dataZoom: [{
              type: 'inside',
              start: 0,
              end: 10
            },
            {
              start: 0,
              end: 10
            }],
            legend: {
              data: ['油量', '速度', '里程'],
              selected: {
                '油量': true,
                '速度': false,
                '里程': true
              }
            },
            series: [
              {
                name: '油量',
                type: 'line',
                symbol: 'none',
                sampling: 'lttb',
                data: oil,
                itemStyle: {
                  normal: {
                    color: '#2F4554'
                  }
                }
              },
              {
                name: '里程',
                type: 'line',
                symbol: 'none',
                sampling: 'lttb',
                data: mile,
                itemStyle: {
                  normal: {
                    color: '#CE5C59'
                  }
                }
              },
              {
                name: '速度',
                type: 'line',
                symbol: 'none',
                sampling: 'lttb',
                data: ve,
                itemStyle: {
                  normal: {
                    color: '#7DB1B7'
                  }
                }
              },
            ]
          }
        }
      })
    },
    load () {
      this.$nextTick(() => {
        if (this.tableDataBase.length === 0) return;
        this.tableData = [
          ...this.tableData,
          ...this.tableDataBase.splice(0, 30)
          //每次滚动加载从数据仓库中取出10条数据
        ];
      })
    },
    exportData () {
      this.downLoadStatus = true;
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId") || localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName: `油量数据-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.form),
        queryPath: '/oilConsumeCount/queryExport'
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    }
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
}
</script>
<style lang="scss" scoped>
.Oil-consumption {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
</style>