<template>
  <div class="searchForm">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      class="demo-form-inline"
      label-position="left"
      label-width="86px"
    >
      <el-form-item label="车牌号：" prop="vehicleNo">
        <el-select
          class="select-width"
          v-model="form.vehicleNo"
          filterable
          remote
          collapse-tags
          placeholder="请输入关键词"
          :remote-method="remoteMethod"
          :loading="loading"
          :reserve-keyword="false"
        >
          <el-option v-for="item in options" :key="item.id" :label="item.cph" :value="item['vehicleNo']"></el-option>
        </el-select>
      </el-form-item>
      <!-- 选择时间： -->
      <el-form-item label="开始时间：" prop="start">
        <el-date-picker
          v-model="form.start"
          type="datetime"
          placeholder="选择开始时间"
          :clearable="false"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="结束时间：" prop="end">
        <el-date-picker
          v-model="form.end"
          type="datetime"
          placeholder="选择结束时间"
          :clearable="false"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label-width="0px">
        <el-button size="small" type="primary" @click="search">查询</el-button>
        <slot />
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  formatDate,
  getStartDate,
  checkTimeLimitDay,
  getCurentDate,
  checkTimeLimit,
  checkLimitDate
} from "@/common/utils/index";
import { queryVehicleFuzzyNew } from '@/api/lib/refreshAlarm.js'
import carTree from '@/components/carTree/carSelectTree.vue'
export default {
  components: {
    carTree
  },
  props: {
    //能否选择今天
    chooseToday: {
      type: Boolean,
      default: false
    },
  },
  data () {
    // 不能超前选择
    const validateDateForward = (rule, value, callback) => {
      if (this.chooseToday) {
        if (
          new Date() <= value[0] ||
          new Date() <= value[1]
        ) {
          callback(new Error('不能选择未来时间'))
        } else {
          callback();
        }
      } else {
        if (new Date(getStartDate()) <= value[0] || new Date(getStartDate()) <= value[1]) {
          callback(new Error("不能选择今天及未来时间"));
        } else {
          callback();
        }
      }
    };
    // 详细时间段
    let validateDateTime1 = (rule, value, callback) => {
      if (value != null && !checkTimeLimit(value, this.form.end)) {
        callback(new Error('开始时间必须小于结束时间'))
      }
      if (value != null && !checkTimeLimitDay(value, this.form.end,1)) {
        callback(new Error('只能查询1天内的数据'))
      } else {
        callback()
      }
    }
    let validateDateTime2 = (rule, value, callback) => {
      if (value != null && !checkTimeLimit(this.form.start, value)) {
        callback(new Error('结束时间必须大于开始时间'))
      }
      if (value != null && !checkTimeLimitDay(this.form.start, value,1)) {
        callback(new Error('只能查询1天内的数据'))
      } else {
        callback()
      }
    }
    return {
      options: [],
      loading:false,
      form: {
        vehicleNo:'',//车架号
        start: new Date(getStartDate()), // 开始时间
        end: new Date(getCurentDate()), // 结束时间
      },
      formRules: {
        vehicleNo: [
          { required: true, message: "请选择车辆", trigger: "blur" }
        ],
        start: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime1, trigger: 'blur' }
        ],
        end: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime2, trigger: 'blur' }
        ],
      },
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
    }
  },

  methods: {
    // 获取车辆数据
    remoteMethod (query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let data = { cph: query, deviceType:sessionStorage.getItem("system") }
          queryVehicleFuzzyNew(data).then((res) => {
            if (res.code === 1000) {
              this.options = res.data
            }
          })
        }, 200)
      } else {
        this.options = []
      }
    },
    search () {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = { ...this.form };
          data.start = formatDate(this.form.start)
          data.end = formatDate(this.form.end)
          this.$emit("getFormVal", data);
        }
      })
    },

  }
}
</script>
<style lang="scss" scoped>
</style>
